<template>
  <div>
    <b-card>
      <div class="w-100 my-1 mb-2 float-left pl-1">
        <div class="d-flex align-items-center float-left">
          <a class="mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a>
          <h3 class="m-0 font-weight-600">
            {{ appTitle || $t("Forum") }}
          </h3>
        </div>
        <b-button
          v-if="canAdd || isStaff"
          variant="outline-primary"
          class="float-right mr-1"
          @click="exportCsv"
        >
          <div v-if="!isDownloading">
            {{ $t("backoffice.members.export") }}
          </div>
          <b-spinner v-else small class="spinner" />
        </b-button>
        <b-button
          v-if="canAdd || isStaff"
          v-b-modal.modal-create-forums
          class="float-right mr-1"
          variant="primary"
        >
          {{ $t("forums.create-title") }}
        </b-button>
      </div>
      <!-- Loading -->
      <b-card-body>
        <div v-if="isLoading">
          <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
        </div>

        <!-- Container with data -->

        <div v-else-if="items.length > 0" class="cards-container">
          <div
            id="scrollContainer"
            class="questions-container pb-2 scrollContainerClass"
            style="max-height: 800px: overflow-y: scroll"
          >
            <!-- Items loop -->
            <div
              v-for="(item, index) in items"
              :key="index"
              class="x-scroll-element mx-0"
            >
              <b-card v-if="canIndex(item)" class="card-question" no-body>
                <b-link :to="{ name: 'forum', params: { id: item.key } }">
                  <!-- Right: Main content -->
                  <div class="px-2 pb-1 d-flex align-items-center">
                    <div class="w-100">
                      <div class="text-secondary pt-1 font-weight-normal">
                        <span v-if="item.createdByUser">
                          <b-avatar
                            size="38"
                            :src="getImageSrc(item.createdByUser.avatarURL)"
                            :alt="item.createdByUser.name"
                            class="user-avatar mr-50"
                          />
                          {{ $t("common.created-by") }}
                          {{ item.createdByUser.name }}
                          {{ item.createdByUser.surname }}
                          <b-badge
                            v-if="item.status"
                            class="float-right mt-50"
                            :variant="getBadgeVariant(item.status)"
                          >
                            {{ getBadgeText(item.status) }}
                          </b-badge>
                        </span>
                      </div>
                      <h4 class="font-weight-extrabold pl-4 mt-1 mb-50">
                        {{
                          item.name[currentLocale] ||
                            Object.values(item.name)[0]
                        }}
                      </h4>
                      <p
                        v-if="item.description"
                        class="pl-4 mb-1 html-text-ellipsis-3 text-secondary content-container"
                        v-html="
                          item.description[currentLocale] ||
                            Object.values(item.description)[0]
                        "
                      />
                      <b-card-text class="w-100 pl-4 mt-2 text-muted">
                        <span>
                          <feather-icon
                            size="20"
                            :class="
                              item.likedByMember
                                ? 'profile-likes-primary'
                                : 'profile-icon'
                            "
                            icon="ThumbsUpIcon"
                            class="mr-25"
                            @click.stop.prevent="toggleLike(item.key)"
                          />
                          {{ item.totalLikes }}
                        </span>

                        <span class="mx-1">
                          <feather-icon
                            role="button"
                            size="20"
                            icon="MessageSquareIcon"
                            class="mr-25"
                          />
                          {{ item.answerCount || 0 }}
                        </span>

                        <span>
                          <feather-icon
                            size="20"
                            icon="ClockIcon"
                            class="mr-25"
                          />
                          {{ timestampToNow(item.createdAt) }}
                        </span>
                      </b-card-text>
                    </div>
                  </div>
                </b-link>
              </b-card>
            </div>
            <b-spinner
              v-if="isLoadingNextPage"
              type="grow"
              small
              class="mx-auto my-2 d-block"
            />
          </div>

          <!-- Load More items -->
          <div
            v-if="items.length < itemsData.meta.total"
            class="w-100 float-left"
          >
            <b-button
              v-if="!isLoadingNextPage"
              class="center-x my-3"
              variant="outline-primary"
              @click="handleLoadOfNewItems"
            >
              {{ $t("action.load-more") }}
            </b-button>
            <!--     <div v-else class="w-100">
              <b-spinner
                class="center-x my-3"
                small
                variant="primary"
                type="grow"
                label="Loading..."
              />
            </div> -->
          </div>
        </div>

        <!-- Container without data: Placeholder -->
        <!-- Placeholder -->
        <b-row v-else class="horizontal-placeholder">
          <b-col cols="12">
            <img :src="placeholder">
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{ $t("available.message", { itemName: "Forum" }) }}
            </p>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Create Forum -->
    <ForumCreateEditModal 
      :forum="forumToEdit"
    />
    <loading-modal v-model="isDownloading" v-ripple.400="'rgba(234, 84, 85, 0.15)'" />
  </div>
</template>

<script>
import ProductsPlaceholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import { getImageResource } from '@/@core/utils/image-utils';
import FileTypes from '@/@core/constants/FileTypes';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import moment from 'moment';
import ForumPlaceholder from '@/assets/images/placeholders/light/forums.svg';
import FileUpload from '@core/components/files/FileUpload.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import Ripple from 'vue-ripple-directive';
import LoadingModal from '@core/components/modal/LoadingModal.vue';
import ForumCreateEditModal from './ForumCreateEditModal.vue';


export default {
  name: 'ForumsList',
  components: {
    FileUpload,
    LoadingModal,
    ForumCreateEditModal
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      form: {},
      imageChallenge: null,
      imageSrc: null,
      isLoading: false,
      isExportModalOpened: false,
      isDownloading: false,
      forumToEdit: null
    };
  },
  computed: {
    appId() {
      return 28;
    },
    app() {
      return this.$store.getters.apps.apps[this.appId];
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName,
      );
    },
    FileTypes() {
      return FileTypes;
    },
    itemsData() {
      return this.$store.getters.forums.list;
    },
    items() {
      return this.itemsData?.unpaginated || [];
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    ProductsPlaceholder() {
      return ProductsPlaceholder;
    },
    placeholder() {
      return ForumPlaceholder;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        'create',
        'forum',
        this.loggedMemberRoles,
        this.collective,
      );
    },
  },
  watch: {
    imageChallenge(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.updateBreadcrumbs();
    await this.fetchData();
    /* this.isLoading = true;
    this.isLoading = false; */
  },
  methods: {
    async exportCsv() {
      this.isDownloading = true;
      const response = await this.$store.dispatch("exportFiles", {
        itemType: "forums/export",
        requestConfig: {},
      });
      setTimeout(this.csv(response), 10000);
      this.isDownloading = false;
    },
    csv(response) {
      const hiddenElement = document.createElement("a");
      hiddenElement.href = response.data.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = "Forums.csv";
      hiddenElement.click();
    },
    updateBreadcrumbs() {
      this.$store.commit('app/SET_BREADCRUMBS', null);
    },
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    canIndex(item) {
      if (
        this.loggedUser?.key === item?.createdByUser?.key
        || checkPermissions(
          'index',
          'forum',
          this.loggedMemberRoles,
          this.collective,
        )
      ) {
        return true;
      }
      return false;
    },
    timestampToNow(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).fromNow();
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    getBadgeVariant(status) {
      if (!status || status === 42658) {
        return 'warning';
      }
      if (status == 42659 || status === 42736) {
        return 'success';
      }
    },
    getBadgeText(status) {
      if (!status || status === 42658) {
        return this.$t('forums.open');
      }
      if (status == 42659 || status === 42736) {
        return this.$t('forums.closed');
      }
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    // TODO: fetch second page if there are items in store
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'forums',
        storedKey: 'list',
        forceAPICall: true,
        page: this.lastLoadedPage,
        requestConfig: {
          count: 16,
          orderByDate: 1,
        },
      });
      this.updateBreadcrumbs();
    },
    async toggleLike(key) {
      // TODO: Check with BE itemTypes and morphTypes
      await this.$store.dispatch('toggleLike', {
        itemType: 'forums',
        morphType: 'forum',
        key,
        storedKey: 'list',
      });
    },
    timestampToTime(timestamp) {
      return moment(timestamp).format('DD-MM-YYYY');
    },
    /*     updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t('Forum'),
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    }, */
  },
};
</script>

<style>
.content-container img {
  max-width: 90%;
}
/* TODO: fix responsive with bootstrap, better than media queries */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -3rem;
  }
}
</style>
